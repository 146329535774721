import React from 'react'
import {Pressable, StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import {Trans} from '@lingui/macro'

import {useCheckVersion} from '#/lib/hooks/Tools'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {useProfileQuery} from '#/state/queries/profile'
import {useSession} from '#/state/session'
import {useSetDrawerOpen} from '#/state/shell'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {LogoVisitorHorizontal} from '#/view/icons/LogoVisitorHorizontal'
import {atoms as a, useTheme} from '#/alf'
import {DesktopSearch} from './desktop/Search'

export function TopNav() {
  const t = useTheme()
  const {isDesktop, isTablet, isMobile} = useWebMediaQueries()
  const {isVersionOutdated, refreshPage} = useCheckVersion()
  const {hasSession, currentAccount} = useSession()
  const {data: profile} = useProfileQuery({did: currentAccount?.did})
  const setDrawerOpen = useSetDrawerOpen()

  return (
    <View style={[a.w_full, a.flex_row]}>
      <View
        style={[
          a.w_full,
          a.flex_row,
          a.justify_between,
          {paddingTop: 10},
          isDesktop && styles.destopBar,
          isTablet && styles.tabletBar,
          isMobile && styles.mobileBar,
        ]}>
        {hasSession && isMobile && (
          <TouchableOpacity
            accessibilityRole="button"
            disabled={true}
            // style={styles.menu}
            onPress={() => setDrawerOpen(true)}>
            <UserAvatar
              size={32}
              avatar={profile?.avatar}
              usePlainRNImage={true}
              type={profile?.associated?.labeler ? 'labeler' : 'user'}
            />
          </TouchableOpacity>
        )}
        <View
          style={
            isMobile
              ? [a.absolute, {left: '50%', transform: 'translateX(-50%)'}]
              : {}
          }>
          <LogoVisitorHorizontal
            style={[isMobile ? {transform: 'scale(0.8)'} : {marginTop: 5}]}
          />
        </View>
        <View style={[a.flex_row, a.gap_2xl]}>
          {isVersionOutdated && (
            <Pressable
              accessibilityRole="button"
              onPress={refreshPage}
              style={[
                a.align_center,
                a.justify_center,
                isMobile ? {marginTop: 5} : {marginTop: 10},
                {
                  height: 24,
                  paddingHorizontal: 10,
                  backgroundColor: t.palette.primary,
                  borderRadius: 40,
                },
              ]}>
              <Text style={[a.font_semibold]}>
                <Trans>Update</Trans>
              </Text>
              <View
                style={[
                  {
                    width: 10,
                    height: 10,
                    borderWidth: 1.5,
                    borderColor: t.atoms.text.color,
                    backgroundColor: '#FF453A',
                    borderRadius: 5,
                    position: 'absolute',
                    right: 0,
                    top: -3,
                  },
                ]}
              />
            </Pressable>
          )}
          {false && <DesktopSearch />}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  destopBar: {
    // @ts-ignore Web only
    width: 844,
    paddingHorizontal: 30,
  },
  tabletBar: {
    paddingHorizontal: 30,
  },
  mobileBar: {
    paddingHorizontal: 16,
  },
})
