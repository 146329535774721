import {useCallback} from 'react'

import {TELEGRAM_BOT_URL} from '#/lib/constants'
import {useSession} from '#/state/session'
import {supports} from '#/tele/support'
import {hasExternalNotify, hasWebviewProxy, isIframe} from '../tele/env'

export const teleShare = (url?: string, text?: string) => {
  url = url || TELEGRAM_BOT_URL
  text =
    text ||
    `🚀 Such mind-blowing secrets on Sipz! 🔥🎉 Let's dive into this together and spill the tea! 🍵👀`
  // url = encodeURIComponent(url)

  shareUrl(url, text)
}

export const postShare = (post: string) => {
  var url = TELEGRAM_BOT_URL

  try {
    const postUrl = new URL(post)
    url = url + '?startapp=' + btoa(postUrl.pathname)
    teleShare(url)
  } catch (error) {
    console.log('sharing error', error)
  }
}

export const profileShare = () => {
  var url = TELEGRAM_BOT_URL

  try {
    url = url + '?startapp=' + btoa(window.location.pathname)
    teleShare(url)
  } catch (error) {
    console.log('sharing error', error)
  }
}

export const useInviteFriend = function () {
  const {currentAccount} = useSession()
  const inviteFriend = useCallback(() => {
    let url =
      window.location.origin === 'https://tg-test.insider.win'
        ? 'https://t.me/InsiderTesting_bot/insidertest'
        : 'https://t.me/Sipz_io_bot/Sipz'
    try {
      url =
        url +
        '?startapp=' +
        btoa(`/?invite_handler=${currentAccount?.handle}&source=2`)
      teleShare(url)
    } catch (error) {
      console.log('sharing error', error)
    }
  }, [currentAccount?.handle])

  return inviteFriend
}

export const teleOpenLink = (url: string) => {
  openLink(url)
}

export const postEvent = (eventType: string, eventData: any) => {
  // Telegram Web.
  if (isIframe()) {
    return window.parent.postMessage(
      JSON.stringify({eventType, eventData}),
      // postOptions.targetOrigin || targetOriginFn(),
      '*',
    )
  }

  // Telegram for Windows Phone or Android.
  if (hasExternalNotify(window)) {
    window.external.notify(JSON.stringify({eventType, eventData}))
    return
  }

  // Telegram for iOS and macOS.
  if (hasWebviewProxy(window)) {
    window.TelegramWebviewProxy.postEvent(eventType, JSON.stringify(eventData))
    return
  }

  console.error('Telegram postEvent: unsupported platform')
}

const openTelegramLink = (url: string) => {
  const {hostname, pathname, search} = new URL(url, 'https://t.me')
  if (hostname !== 't.me') {
    console.error(
      `URL has not allowed hostname: ${hostname}. Only "t.me" is allowed`,
    )
  }

  const version = (window as any).Telegram.WebApp.version || '1.0'
  if (!supports('web_app_open_tg_link', version)) {
    window.location.href = url
    return
  } else if (isIframe()) {
    window.open(url, '_blank')
    return
  }

  postEvent('web_app_open_tg_link', {path_full: pathname + search})
}

const openLink = (url: string, options?: any) => {
  const formattedUrl = url //createSafeURL(url).toString()

  // If the method is not supported, we are doing it in legacy way.
  const version = (window as any).Telegram.WebApp.version || '1.0'
  if (!supports('web_app_open_link', version)) {
    window.open(formattedUrl, '_blank')
    return
  }

  // Otherwise, do it normally.
  postEvent('web_app_open_link', {
    url: formattedUrl,
    try_browser: options?.tryBrowser,
    try_instant_view: options?.tryInstantView,
  })
}

const shareUrl = (url: string, text: string) => {
  openTelegramLink(
    `https://t.me/share/url?` +
      new URLSearchParams({url, text: text || ''})
        .toString()
        // By default, URL search params encode spaces with "+".
        // We are replacing them with "%20", because plus symbols are working incorrectly
        // in Telegram.
        .replace(/\+/g, '%20'),
  )
}
