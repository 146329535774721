import React from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {Trans} from '@lingui/macro'
import {useQueryClient} from '@tanstack/react-query'

import {isMobile, isWeb} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {TelegramBindStep} from '#/view/com/modals/TelegramModal/config'
import {telegramUserBaseInfo} from '#/view/com/modals/TelegramModal/hooks'
import {CenteredView} from '#/view/com/util/Views'
import {SvgComponent as TelegramLink} from '#/view/icons/TelegramLink'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {Telegram} from '#/components/icons/Telegram'
import {Loader} from '#/components/Loader'
import {TextLinearGradient} from '#/components/TextLinearGradient'

type Props = {
  bind: boolean
  binding: boolean
  shouldInviteCount?: number
  // handleBind: () => Promise<void>
}
export function LinkTelegram({
  bind,
  binding,
}: // handleBind,
// shouldInviteCount,
Props) {
  // const {client} = useTelegramClient()
  const t = useTheme()
  const {openModal} = useModalControls()
  const queryClient = useQueryClient()

  const onPressLink = async () => {
    // if (client && (await client.checkAuthorization())) {
    //   await handleBind()
    queryClient.invalidateQueries({queryKey: telegramUserBaseInfo})
    // } else {

    openModal({
      name: 'telegram',
      currentStep: bind
        ? TelegramBindStep.SelectContact
        : TelegramBindStep.Init,
    })
    // }
  }

  let desc = (
    <>
      <Text style={[styles.title, t.atoms.text]}>
        <Trans>Invite your Telegram friends</Trans>
      </Text>
      <Text style={[{fontSize: 13, fontWeight: 700}, t.atoms.text]}>
        <Trans>Unlock more Circles as you grow new connections.</Trans>
      </Text>
    </>
  )
  if (t.name === 'dark' && isWeb) {
    desc = (
      <>
        <TextLinearGradient
          text="Invite your Telegram friends"
          textStyle={{
            backgroundClip: 'text',
            color: 'transparent',
            fontSize: 38,
            fontWeight: '800',
            textAlign: 'center',
          }}
          colors={['#FFF5D0', '#FCD010']}
        />
        <TextLinearGradient
          text="Unlock more Circles as you grow new connections."
          textStyle={{
            backgroundClip: 'text',
            color: 'transparent',
            fontSize: 13,
            fontWeight: 700,
          }}
          colors={['#FFF5D0', '#FCD010']}
        />
      </>
    )
  }

  return (
    <CenteredView sideBorders={!isMobile} style={[a.flex_1]}>
      <View style={styles.container}>
        {desc}
        <TelegramLink />
        <Button
          style={styles.linkButton}
          color="primary"
          variant="solid"
          size="large"
          label="Link Telegram"
          disabled={binding}
          onPress={onPressLink}>
          <Telegram style={{color: t.palette.black}} />
          <Text
            style={[{fontSize: 16, fontWeight: '600', color: t.palette.black}]}>
            <Trans>Link&Invite</Trans>
          </Text>
          {binding && <Loader />}
        </Button>
        <View>
          <Text style={[styles.subTitle, t.atoms.text_sub]}>
            <Trans>Share your stories and moments with friends.</Trans>
          </Text>
        </View>
      </View>
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    flex: 1,
    padding: 20,
    paddingTop: 80,
    gap: 20,
    alignItems: 'center',
    // backgroundColor: 'rgba(0,0,0,0.5)',
  },
  linkButton: {
    width: '70%',
  },
  title: {
    fontSize: 38,
    fontWeight: '800',
    textAlign: 'center',
  },

  subTitle: {
    fontSize: 14,
    textAlign: 'center',
    // color: '#333333',
  },
})
